import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HomePage } from './components/Home';
import { PaymentRedirect } from './components/PaymentRedirect';

function App() {
	return (
		<div>
			<Router>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route path="/pay/:paymentId" element={<PaymentRedirect />} />
					<Route path="/v1/pay/:paymentId" element={<PaymentRedirect />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App