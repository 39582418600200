export default function Logo({ colourRed = 0, colourGreen = 85, colourBlue = 61 }) {
	const environmentColour = `rgb(${colourRed},${colourGreen},${colourBlue})`

	// prod 0,85,61
	return (
		<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="512" height="381" viewBox="0 0 512 381">
			<defs>
			</defs>
			<g transform="matrix(1 0 0 1 252.95 193.25)" clipPath="url(#CLIPPATH_20)"  >
				<clipPath id="CLIPPATH_20" >
					<rect transform="matrix(1 0 0 1 1.72 22.34)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill="rgb(248,151,29)"
					fillRule="nonzero"
					opacity= "1"
					 transform=" translate(-252.95, -193.25)" d="M 417.91 359.83 L 37.6 285.9 c -5.3 -1.03 -8.77 -6.17 -7.74 -11.47 L 76.52 34.41 c 1.03 -5.3 6.17 -8.77 11.47 -7.74 l 380.31 73.92 c 5.3 1.03 8.77 6.17 7.74 11.47 l -46.66 240.02 c -1.03 5.3 -6.17 8.77 -11.47 7.74" />
			</g>
			<g transform="matrix(1 0 0 1 256.4 190.82)" clipPath="url(#CLIPPATH_21)"  >
				<clipPath id="CLIPPATH_21" >
					<rect transform="matrix(1 0 0 1 -1.72 24.78)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill="rgb(255,255,255)"
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-256.4, -190.81)" d="M 421.36 357.4 L 41.05 283.47 c -5.3 -1.03 -8.77 -6.17 -7.74 -11.47 L 79.97 31.98 c 1.03 -5.3 6.17 -8.77 11.47 -7.74 l 380.31 73.92 c 5.3 1.03 8.77 6.17 7.74 11.47 l -46.66 240.02 c -1.03 5.3 -6.17 8.77 -11.47 7.74" />
			</g>
			<g transform="matrix(1 0 0 1 145.32 174.6)"  >
				<polygon
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="butt"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 points="14.06,-7.82 -0.76,-10.71 -2.14,-3.58 11.52,-0.93 10.17,6 -3.49,3.34 -6.6,19.34 -15.41,17.62 -8.23,-19.34 15.41,-14.75 14.06,-7.82 " />
			</g>
			<g transform="matrix(1 0 0 1 170.2 186.06)" clipPath="url(#CLIPPATH_22)"  >
				<clipPath id="CLIPPATH_22" >
					<rect transform="matrix(1 0 0 1 84.47 29.53)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-170.2, -186.06)" d="M 172.23 197.38 c -0.65 0.4 -1.24 0.73 -1.76 1.05 c -0.53 0.31 -1.2 0.58 -2.03 0.84 c -0.81 0.22 -1.58 0.37 -2.32 0.43 c -0.73 0.04 -1.72 -0.04 -2.98 -0.28 c -2.33 -0.45 -4.1 -1.66 -5.32 -3.61 c -1.22 -1.96 -1.59 -4.2 -1.1 -6.72 c 0.4 -2.08 1.12 -3.68 2.16 -4.79 c 1.02 -1.13 2.35 -1.91 3.96 -2.39 c 1.64 -0.47 3.52 -0.65 5.64 -0.55 c 2.13 0.11 4.39 0.3 6.8 0.61 l 0.03 -0.15 c 0.3 -1.55 -0.02 -2.74 -0.98 -3.53 c -0.95 -0.82 -2.51 -1.42 -4.64 -1.83 c -0.97 -0.19 -2.14 -0.22 -3.51 -0.09 c -1.37 0.13 -2.73 0.37 -4.09 0.74 l -0.71 -0.14 l 1.3 -6.71 c 0.87 -0.09 2.26 -0.11 4.14 -0.11 c 1.89 0.02 3.76 0.21 5.63 0.57 c 4.55 0.88 7.71 2.33 9.49 4.34 c 1.76 1.99 2.34 4.64 1.7 7.93 l -3.69 18.98 l -8.31 -1.62 l 0.57 -2.96 Z M 173.08 193 l 1.13 -5.81 c -1.41 -0.13 -2.56 -0.21 -3.45 -0.29 c -0.88 -0.04 -1.77 0 -2.66 0.16 c -0.77 0.12 -1.41 0.42 -1.92 0.87 c -0.51 0.46 -0.84 1.15 -1.02 2.04 c -0.26 1.32 -0.1 2.28 0.47 2.89 c 0.58 0.62 1.52 1.06 2.82 1.32 c 0.71 0.14 1.49 0.1 2.31 -0.1 c 0.81 -0.2 1.58 -0.55 2.31 -1.07"  />
			</g>
			<g transform="matrix(1 0 0 1 197.2 189.91)" clipPath="url(#CLIPPATH_23)"  >
				<clipPath id="CLIPPATH_23" >
					<rect transform="matrix(1 0 0 1 57.47 25.68)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-197.2, -189.91)" d="M 207.19 186.95 l -0.69 -0.13 c -0.29 -0.19 -0.72 -0.35 -1.3 -0.54 c -0.59 -0.17 -1.23 -0.32 -1.95 -0.46 c -0.85 -0.16 -1.79 -0.22 -2.77 -0.15 c -1.01 0.09 -1.98 0.23 -2.94 0.42 l -3.61 18.58 l -8.3 -1.61 l 5.42 -27.91 l 8.3 1.62 l -0.8 4.09 c 0.44 -0.3 1.06 -0.66 1.82 -1.08 c 0.78 -0.42 1.46 -0.74 2.07 -0.98 c 0.67 -0.25 1.42 -0.44 2.26 -0.56 c 0.84 -0.12 1.6 -0.11 2.32 0.03 c 0.26 0.05 0.56 0.13 0.87 0.2 c 0.33 0.07 0.62 0.16 0.89 0.24 l -1.6 8.26 Z" />
			</g>
			<g transform="matrix(1 0 0 1 228.08 196.99)" clipPath="url(#CLIPPATH_24)"  >
				<clipPath id="CLIPPATH_24" >
					<rect transform="matrix(1 0 0 1 26.59 18.6)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-228.08, -196.99)" d="M 237.82 213.2 l 2.71 -13.92 c 0.27 -1.38 0.48 -2.54 0.61 -3.49 c 0.13 -0.96 0.14 -1.75 0.03 -2.41 c -0.12 -0.64 -0.42 -1.13 -0.85 -1.5 c -0.45 -0.37 -1.12 -0.64 -2.02 -0.82 c -0.63 -0.12 -1.28 -0.1 -1.95 0.06 c -0.67 0.18 -1.43 0.46 -2.26 0.85 l -3.84 19.76 l -8.29 -1.61 l 2.71 -13.92 c 0.26 -1.36 0.45 -2.52 0.58 -3.48 c 0.12 -0.96 0.12 -1.77 0.01 -2.42 c -0.12 -0.64 -0.41 -1.13 -0.85 -1.5 c -0.46 -0.37 -1.12 -0.64 -2 -0.81 c -0.68 -0.13 -1.38 -0.1 -2.09 0.1 c -0.71 0.2 -1.42 0.48 -2.11 0.81 l -3.84 19.76 l -8.3 -1.61 l 5.42 -27.91 l 8.3 1.62 l -0.61 3.14 c 1.59 -0.96 3.04 -1.65 4.37 -2.08 c 1.33 -0.45 2.75 -0.52 4.25 -0.23 c 1.61 0.31 2.96 0.98 4.04 2.04 c 1.08 1.05 1.77 2.45 2.11 4.17 c 1.89 -1.25 3.62 -2.16 5.19 -2.73 c 1.58 -0.55 3.09 -0.68 4.55 -0.4 c 1.21 0.24 2.29 0.65 3.18 1.25 c 0.89 0.61 1.6 1.38 2.11 2.34 c 0.57 1.04 0.91 2.21 1.05 3.52 c 0.14 1.29 0 2.95 -0.38 4.93 l -3.52 18.13 l -8.3 -1.61 Z"  />
			</g>
			<g transform="matrix(0.19 -0.98 0.98 0.19 259.76 197.4)"  >
				<rect
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="butt"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 x="-19.69" y="-4.225" rx="0" ry="0" width="39.38" height="8.45" />
			</g>
			<g transform="matrix(1 0 0 1 279.09 207.2)" clipPath="url(#CLIPPATH_25)"  >
				<clipPath id="CLIPPATH_25" >
					<rect transform="matrix(1 0 0 1 -24.41 8.39)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-279.09, -207.2)" d="M 281.11 218.54 c -0.66 0.39 -1.24 0.73 -1.77 1.05 c -0.52 0.31 -1.21 0.58 -2.04 0.84 c -0.81 0.22 -1.58 0.37 -2.31 0.43 c -0.72 0.04 -1.73 -0.04 -2.98 -0.28 c -2.33 -0.45 -4.11 -1.66 -5.32 -3.61 c -1.21 -1.95 -1.58 -4.19 -1.08 -6.72 c 0.4 -2.08 1.11 -3.68 2.14 -4.8 c 1.03 -1.12 2.36 -1.91 3.98 -2.38 c 1.62 -0.48 3.52 -0.65 5.62 -0.56 c 2.13 0.11 4.41 0.31 6.83 0.61 l 0.03 -0.15 c 0.3 -1.55 -0.04 -2.74 -1 -3.54 c -0.94 -0.82 -2.5 -1.42 -4.63 -1.83 c -0.98 -0.19 -2.14 -0.22 -3.51 -0.09 c -1.37 0.13 -2.74 0.37 -4.09 0.74 l -0.69 -0.14 l 1.3 -6.71 c 0.87 -0.08 2.25 -0.11 4.14 -0.11 c 1.88 0.01 3.75 0.21 5.61 0.57 c 4.56 0.89 7.73 2.33 9.49 4.34 c 1.78 1.99 2.35 4.64 1.71 7.93 l -3.69 18.98 l -8.3 -1.62 l 0.57 -2.96 Z M 281.96 214.17 l 1.13 -5.81 c -1.43 -0.14 -2.57 -0.22 -3.46 -0.29 c -0.88 -0.04 -1.76 0 -2.65 0.16 c -0.78 0.11 -1.42 0.41 -1.93 0.86 c -0.49 0.47 -0.83 1.15 -1.01 2.04 c -0.26 1.32 -0.09 2.28 0.47 2.89 c 0.57 0.62 1.51 1.06 2.81 1.31 c 0.72 0.14 1.5 0.11 2.31 -0.1 c 0.82 -0.19 1.58 -0.55 2.33 -1.07" />
			</g>
			<g transform="matrix(1 0 0 1 308.91 212.7)" clipPath="url(#CLIPPATH_26)"  >
				<clipPath id="CLIPPATH_26" >
					<rect transform="matrix(1 0 0 1 -54.24 2.89)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-308.91, -212.7)" d="M 319.39 229.05 l -8.29 -1.61 l 2.69 -13.82 c 0.22 -1.13 0.39 -2.27 0.53 -3.39 c 0.13 -1.13 0.12 -2 -0.02 -2.57 c -0.16 -0.68 -0.47 -1.2 -0.96 -1.58 c -0.49 -0.38 -1.17 -0.65 -2.08 -0.83 c -0.68 -0.13 -1.38 -0.14 -2.14 0 c -0.75 0.12 -1.57 0.4 -2.47 0.83 l -3.84 19.76 l -8.3 -1.61 l 5.42 -27.91 l 8.3 1.62 l -0.61 3.14 c 1.6 -0.96 3.1 -1.64 4.52 -2.05 c 1.42 -0.43 2.9 -0.5 4.45 -0.19 c 2.73 0.53 4.62 1.83 5.7 3.88 c 1.09 2.09 1.3 4.82 0.63 8.23 l -3.52 18.13 Z" />
			</g>
			<g transform="matrix(1 0 0 1 340.93 214.69)" clipPath="url(#CLIPPATH_27)"  >
				<clipPath id="CLIPPATH_27" >
					<rect transform="matrix(1 0 0 1 -86.25 0.89)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-340.93, -214.7)" d="M 349.12 234.83 l -8.3 -1.62 l 0.56 -2.91 c -0.62 0.36 -1.32 0.73 -2.12 1.14 c -0.78 0.4 -1.46 0.68 -2.04 0.83 c -0.72 0.22 -1.44 0.38 -2.11 0.45 c -0.69 0.06 -1.48 0.01 -2.37 -0.16 c -3.04 -0.59 -5.17 -2.37 -6.42 -5.35 c -1.25 -2.99 -1.45 -6.69 -0.59 -11.09 c 0.48 -2.45 1.19 -4.51 2.14 -6.18 c 0.94 -1.68 2.08 -3.07 3.39 -4.2 c 1.2 -1.02 2.55 -1.74 4.06 -2.2 c 1.51 -0.44 3.01 -0.5 4.56 -0.2 c 1.38 0.27 2.5 0.64 3.33 1.13 c 0.83 0.47 1.77 1.15 2.82 2.01 l 2.32 -11.92 l 8.3 1.61 l -7.51 38.66 Z M 342.28 225.67 l 2.69 -13.83 c -0.36 -0.31 -0.85 -0.58 -1.49 -0.85 c -0.65 -0.28 -1.19 -0.46 -1.67 -0.55 c -1.97 -0.38 -3.61 0.07 -4.9 1.35 c -1.28 1.27 -2.19 3.24 -2.7 5.87 c -0.54 2.79 -0.53 4.88 0.02 6.26 c 0.55 1.39 1.71 2.26 3.5 2.61 c 0.73 0.14 1.48 0.13 2.28 -0.03 c 0.81 -0.16 1.56 -0.43 2.27 -0.83" />
			</g>
			<g transform="matrix(1 0 0 1 366.74 223.87)" clipPath="url(#CLIPPATH_28)"  >
				<clipPath id="CLIPPATH_28" >
					<rect transform="matrix(1 0 0 1 -112.06 -8.29)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-366.74, -223.88)" d="M 362.93 238.25 c -1.94 -0.38 -3.73 -0.95 -5.38 -1.73 c -1.66 -0.79 -2.98 -1.59 -4.01 -2.41 l 1.43 -7.36 l 0.69 0.14 c 0.32 0.38 0.71 0.82 1.13 1.29 c 0.43 0.46 1.05 0.99 1.86 1.57 c 0.69 0.52 1.5 1.01 2.41 1.46 c 0.91 0.46 1.92 0.8 3.04 1.02 c 1.15 0.22 2.2 0.24 3.16 0.05 c 0.95 -0.2 1.52 -0.68 1.67 -1.47 c 0.12 -0.64 0 -1.17 -0.34 -1.57 c -0.35 -0.43 -1.07 -0.89 -2.19 -1.41 c -0.59 -0.29 -1.34 -0.62 -2.27 -0.99 c -0.92 -0.39 -1.72 -0.76 -2.4 -1.15 c -1.94 -1.08 -3.3 -2.39 -4.08 -3.92 c -0.78 -1.53 -0.95 -3.37 -0.53 -5.53 c 0.24 -1.24 0.74 -2.37 1.48 -3.35 c 0.76 -1 1.75 -1.82 2.98 -2.47 c 1.21 -0.64 2.64 -1.06 4.29 -1.25 c 1.64 -0.19 3.43 -0.1 5.39 0.28 c 1.85 0.36 3.53 0.89 5.01 1.56 c 1.48 0.7 2.7 1.43 3.66 2.18 l -1.38 7.08 l -0.65 -0.13 c -0.24 -0.29 -0.63 -0.67 -1.16 -1.17 c -0.54 -0.5 -1.07 -0.96 -1.6 -1.34 c -0.62 -0.46 -1.32 -0.88 -2.14 -1.25 c -0.82 -0.39 -1.67 -0.66 -2.54 -0.83 c -1.19 -0.23 -2.25 -0.23 -3.14 0.02 c -0.9 0.27 -1.42 0.73 -1.56 1.4 c -0.12 0.63 0 1.17 0.33 1.61 c 0.34 0.44 1.15 0.96 2.44 1.58 c 0.67 0.31 1.45 0.65 2.32 1 c 0.89 0.36 1.72 0.76 2.51 1.22 c 1.76 1.01 3.02 2.24 3.75 3.68 c 0.75 1.43 0.91 3.18 0.51 5.24 c -0.25 1.3 -0.78 2.5 -1.6 3.55 c -0.81 1.07 -1.84 1.9 -3.03 2.51 c -1.32 0.64 -2.78 1.07 -4.4 1.24 c -1.62 0.18 -3.51 0.06 -5.65 -0.35" />
			</g>
			<g transform="matrix(1 0 0 1 266.63 147.09)" clipPath="url(#CLIPPATH_29)"  >
				<clipPath id="CLIPPATH_29" >
					<rect transform="matrix(1 0 0 1 -11.95 68.5)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill="rgb(248,151,29)"
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-266.63, -147.09)" d="M 142.28 128.45 s 45.98 -0.39 88.04 7.78 c 61.87 12.03 72.4 20.36 116.43 32.6 c 18.87 5.36 35.12 9.27 36.07 9.55 c 1.83 0.53 4.85 1.16 4.85 1.16 l 3.32 -17.08 l -246.02 -47.82 l -2.69 13.83 Z" />
			</g>
			<g transform="matrix(1 0 0 1 263.42 161.78)" clipPath="url(#CLIPPATH_30)"  >
				<clipPath id="CLIPPATH_30" >
					<rect transform="matrix(1 0 0 1 -8.75 53.81)" x="-224.995" y="-191.53" rx="0" ry="0" width="449.99" height="383.06" />
				</clipPath>
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-263.42, -161.78)" d="M 141.41 132.91 l -1.93 9.94 l 246.02 47.82 l 1.85 -9.51 s -30.74 -7.5 -48.09 -12.38 c -31.47 -8.86 -49.34 -16.95 -76.54 -22.87 c -13.75 -2.98 -25.86 -6.21 -60.19 -10.12 c -6.4 -0.73 -30.44 -2.52 -41.89 -2.51 c -4.19 0.02 -19.23 -0.38 -19.23 -0.38" />
			</g>
			<g transform="matrix(1 0 0 1 300.59 252.47)"  >
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-300.59, -252.47)" d="M 287.31 249.83 c 1.8 -9.26 8.38 -14.96 17.03 -13.27 c 6.47 1.26 10.05 5.69 9.97 11.59 l -2.83 -0.55 c -0.04 -4.14 -2.66 -7.59 -7.67 -8.56 c -7.2 -1.4 -12.04 3.84 -13.51 11.38 c -1.49 7.67 1.23 14.06 8.25 15.43 c 5.91 1.15 9.92 -2.34 11.38 -7.3 l 2.78 0.54 c -0.75 2.97 -2.49 5.74 -4.73 7.4 c -2.61 1.98 -6.07 2.64 -9.97 1.88 c -8.52 -1.65 -12.54 -9.15 -10.71 -18.53 Z" />
			</g>
			<g transform="matrix(1 0 0 1 324.14 262.23)"  >
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-324.15, -262.23)" d="M 314.33 265.08 c 1.02 -5.27 5.34 -5.54 10.47 -5.16 c 4.47 0.34 6.11 0.39 6.54 -1.84 l 0.08 -0.43 c 0.58 -3 -1.02 -4.82 -4.44 -5.49 c -3.94 -0.77 -6.31 0.46 -7.17 3.72 l -2.61 -0.51 c 1.13 -4.22 4.5 -6.46 10.2 -5.35 c 4.84 0.94 7.45 3.53 6.51 8.38 l -2.34 12.04 c -0.26 1.33 0.15 1.98 1.08 2.21 c 0.3 0.06 0.61 0.03 1.02 0.02 l 0.13 0.02 l -0.38 1.93 c -0.42 0.1 -1.01 0.16 -1.87 -0.01 c -1.93 -0.37 -2.93 -1.59 -2.65 -3.76 l 0.09 -0.47 l -0.13 -0.02 c -1.59 1.51 -4.25 3.13 -8.49 2.3 c -4.12 -0.8 -6.84 -3.46 -6.04 -7.58 Z M 329.9 265.67 l 0.77 -3.98 c -1.56 0.5 -3.82 0.46 -6.02 0.34 c -3.83 -0.21 -6.81 -0.21 -7.55 3.6 c -0.6 3.09 1.54 4.48 4.15 4.99 c 4.85 0.94 8.06 -1.9 8.65 -4.94 Z" />
			</g>
			<g transform="matrix(1 0 0 1 343.55 264.39)"  >
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-343.55, -264.39)" d="M 340.58 253.07 l 2.65 0.52 l -0.8 4.11 l 0.09 0.02 c 1.87 -2.31 4.51 -3.57 7.6 -2.97 c 0.34 0.07 0.55 0.15 0.71 0.23 l -0.51 2.61 c -1.54 -0.57 -3.36 -0.56 -4.92 0.15 c -1.96 0.95 -3.52 2.79 -4.03 5.4 l -2.45 12.59 l -2.65 -0.52 l 4.3 -22.15 Z" />
			</g>
			<g transform="matrix(1 0 0 1 361.3 265.64)"  >
				<path
					stroke="none"
					strokeWidth="0"
					strokeDasharray="none"
					strokeLinecap="round"
					strokeDashoffset="0"
					strokeLinejoin="miter"
					strokeMiterlimit="4"
					fill={environmentColour}
					fillRule="nonzero"
					opacity="1"
					 transform=" translate(-361.3, -265.65)" d="M 349.82 266.42 c 1.43 -7.37 6.46 -10.79 11.73 -9.77 c 3.38 0.66 5.56 2.73 6.47 5.57 l 0.08 0.02 l 2.36 -12.17 l 2.66 0.52 l -5.95 30.63 l -2.66 -0.52 l 0.64 -3.3 l -0.08 -0.02 c -2.12 2.21 -4.61 3.15 -7.96 2.5 c -5.95 -1.16 -8.62 -6.66 -7.29 -13.47 Z M 366.86 269.78 c 1.06 -5.44 -0.78 -9.93 -5.58 -10.87 c -4.37 -0.85 -7.65 2.56 -8.73 8.08 c -1.01 5.18 0.41 9.77 5.08 10.68 c 4.84 0.94 8.15 -2.37 9.22 -7.9 Z" />
			</g>
		</svg>
	)
}