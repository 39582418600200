import Logo from './Logo';
import '../App.css';

export function HomePage({ colourRed = 0, colourGreen = 85, colourBlue = 61 }) {
	const environmentColour = `rgb(${colourRed},${colourGreen},${colourBlue})`

	return (
		<div className="App" style={{ fill: { environmentColour } }} >
			<header className="App-header">
				<Logo colourRed="0" colourGreen="85" colourBlue="61" />
				<a
					className="App-link"
					href="/pay/123"
				>
					Download now
				</a>
			</header>
		</div>
	);
}
