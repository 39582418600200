import React from "react";
import { useParams } from "react-router-dom";

export function PaymentRedirect() {

	const { paymentId } = useParams();

	/**
	 * Determine the mobile operating system.
	 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
	 *
	 * @returns {String}
	 */
	function getMobileOperatingSystem() {
		var userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			console.log(`Windows Phone for ${paymentId}`);
			return;
		}

		if (/android/i.test(userAgent)) {
			console.log(`Android for ${paymentId}`);
			document.location = "https://play.google.com/store/apps/details?id=nz.co.farmlands.card";
			return;
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			console.log(`iOS for ${paymentId}`);
			document.location = "https://apps.apple.com/nz/app/farmlandscard/id6502442009";
			return;
		}

		console.log("unknown");
	}

	getMobileOperatingSystem();
	return (
		<div style={{ display: "flex", flexDirection: "column" }} >
			<div style={{ textAlign: "center", margin: "50px 0px" }} >
				<a href="https://apps.apple.com/nz/app/farmlandscard/id6502442009">
					<img src="https://www.apple.com/v/app-store/b/images/overview/icon_appstore__ev0z770zyxoy_large.png" alt="Apple App Store" style={{ maxMidth: "100%;" }} />
				</a>
			</div>
			<div style={{ textAlign: "center", margin: "50px 0px" }} >
				<a href="https://play.google.com/store/apps/details?id=nz.co.farmlands.card">
					<img src="https://play.google/howplayworks/static/assets/logo/play_logo2x.webp" alt="Google Play Store" style={{ maxMidth: "100%;" }} />
				</a>
			</div>
		</div>
	);
}